<template>
  <div id="pdfDom">
    <div>
      <el-row>
        <el-col :sm="6" :xs="24">
          <el-page-header @back="goBack" :content="$t(title)"> </el-page-header>
        </el-col>
        <el-col :sm="18" :xs="24" class="col-flex"> </el-col>
      </el-row>
    </div>
    <info-list
      title="content.order.orderInfo"
      v-model="orderData"
      :names="names"
      :exhibit="orderExhibit"
      :to="to"
    />
    <template v-if="orderAttachList && orderAttachList.length">
      <h4 class="detail-h4">{{ $t("content.order.Attach list") }}</h4>
      <order-attach-time-line :list="orderAttachList" />
    </template>
    <h4 class="detail-h4">{{ $t("content.order.Sub order list") }}</h4>
    <sui-list
      v-if="orderList.length"
      ref="orderList"
      :column="orderTableColumn"
      :defaultData="orderList"
      :part="part"
    />
    <template v-if="orderPayList.length">
      <h4 class="detail-h4">{{ $t("content.order.paymentdetails") }}</h4>
      <sui-list
        ref="orderPayList"
        :column="orderPayListColumn"
        :defaultData="orderPayList"
      />
    </template>
    <template>
      <h4 class="detail-h4">{{ $t("order.logisticsInformation") }}</h4>
      <el-form
        :model="trackingData"
        ref="trackingData"
        label-width="10%"
        style="background-color: #ffffff; padding: 10px 0; position: relative"
      >
        <div style="display: flex;">
          <div style="width: 100%;">
            <el-form-item
              prop="trackingNumber"
              :label="this.$t('order.trackingNumber')"
              style="position: relative;"
            >
              <el-input
                v-model="trackingData.trackingNumber"
                placeholder=""
                class="input1"
                style="width: 100%"
              ></el-input>
            </el-form-item>
            <el-form-item
              prop="trackingInformation"
              :label="this.$t('order.trackingInformation')"
              style="position: relative"
            >
              <el-input
                v-model="trackingData.trackingInformation"
                placeholder=""
                class="input1"
                style="width: 100%"
              ></el-input>
            </el-form-item>
          </div>
          <div class="gurrent">
            <img
              src="@/assets/default_brand_logo.png"
              v-if="avatarUrl === ''"
              style="width: 70px; height: 70px;margin-left: 50%;transform: translateX(-50%);"
              class="fl ml"
            />
            <el-image
              v-else
              :src="avatarUrl + '?x-oss-process=style/w128'"
              style="width: 70px; height: 70px;margin-left: 50%;transform: translateX(-50%);"
              class="fl ml"
            ></el-image>
            <MaterialSelector
              :count="1"
              type="image"
              style="margin-left: 20px;text-align: center;"
              @receiver="handleSelectionResult($event, 1)"
            >
              <template v-slot:custom-open-button>
                <p class="p2 fl" style="color: red">
                  {{ $t("order.uploadLogisticsOrder") }}<br />
                </p>
              </template>
            </MaterialSelector>
          </div>
        </div>
        <el-button
          @click="updateData"
          style="
            margin: auto;
            width: 300px;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
          "
          type="danger"
          >{{ $t("account.preservation") }}</el-button
        >
      </el-form>
    </template>
  </div>
</template>
  
  <script>
import SuiList from "@/components/s-ui/list";
import InfoList from "@/components/InfoList";
import OrderAttachTimeLine from "@/components/OrderAttachTimeLine";
import MaterialSelector from "@/components/MaterialSelector";
import {
  CanceOrder,
  GoodsReady,
  GetOrderLogisticDetailbyVendor,
  EditOrderLogisticbyVendor,
} from "@/utils/api";
import { formatDataTime } from "@/utils";
export default {
  components: {
    SuiList,
    OrderAttachTimeLine,
    InfoList,
    MaterialSelector,
  },
  data() {
    return {
      part: 1,
      trackingData: {
        trackingNumber: "",
        trackingInformation: "",
      },
      page: "",
      id: 0,
      title: "order.receivingInformation",
      To: "",
      avatarUrl: "",
      orderList: [],
      orderPayList: [],
      orderAttachList: [],
      orderTableColumn: [
        {
          name: "orderNo",
          label: "content.order.orderno",
        },
        {
          name: "productName",
          label: "content.order.productName",
        },
        {
          type: "image",
          name: "productImg",
          label: "content.order.productImg",
        },
        {
          name: "spData",
          label: "content.order.spData",
        },
        {
          name: "pcs",
          label: "content.order.pcs",
        },
        {
          name: "weight",
          label: "productManagement.weight",
        },
        {
          type: "price-fixed",
          name: "price",
          label: "content.order.total",
          width: 150,
        },
        {
          name: "createAt",
          label: "content.order.createAt",
          formatFn: formatDataTime,
          width: 110,
        },
      ],
      orderAttachTableColumn: [
        {
          name: "type",
          label: "content.order.type",
        },
        {
          name: "payMethod",
          label: "content.order.payMethod",
        },
        {
          name: "remark",
          label: "content.order.remark",
        },
        {
          type: "image",
          name: "imgList",
          label: "content.order.imgList",
        },
        {
          name: "createAt",
          label: "content.order.createAt",
          formatFn: formatDataTime,
          width: 110,
        },
      ],
      orderPayListColumn: [
        {
          name: "outTradeNo",
          label: "content.order.orderno",
        },
        {
          type: "price-fixed",
          name: "total",
          label: "content.order.total",
          width: 150,
        },

        {
          name: "payMethod",
          label: "content.order.payMethod",
          payMethod: this.$t("content.order.payMethodText"),
        },
        {
          name: "codeUrl",
          label: "content.order.payCodeUrl",
        },
        {
          name: "status",
          label: "content.order.status",
          payStatus: this.$t("content.order.payStatusText"),
        },
        {
          name: "createAt",
          label: "content.order.createAt",
          formatFn: formatDataTime,
          width: 110,
        },
      ],
      operationByState: {
        // 不同状态下，显示不同的操作按钮 当前状态: [操作后状态, 操作后状态]
        101: [1301],
        301: [401],
      },
      optMap: {
        // 操作按钮枚举
        1301: {
          icon: "el-icon-circle-close",
          type: "danger",
          fun: CanceOrder,
        },
        401: {
          icon: "el-icon-document-checked",
          fun: GoodsReady,
        },
      },
      orderData: {},
      orderExhibit: {
        status: { type: "state" },
        total: { type: "price" },
        logisticsTotal: { type: "price" },
      },
      user: {},
      store: {},
    };
  },
  computed: {
    to: function () {
      return this.orderList[0] ? this.orderList[0].currencyTo : "";
    },
    names: function () {
      return this.$t("content.order");
    },
  },
  created() {
    this.id = Number(this.$route.query.id);
    this.getDetail();
  },
  methods: {
    handleSelectionResult(result, type) {
      if (type === 1) {
        this.avatarUrl = result.filename;
      }
    },
    updateData() {
      console.log("aaaaaaa", this.trackingData, this.id);
      EditOrderLogisticbyVendor({
        id: this.id,
        trackingNumber: this.trackingData.trackingNumber,
        tracking: this.trackingData.trackingInformation,
        trackimg: this.avatarUrl,
      }).then((res) => {
        console.log("0000000", res);
        if (res.errcode == 0) {
          this.$message({
            message: this.$t("common.updateSuccess"),
            type: "success",
          });
        }
      });
    },
    getDetail() {
      console.log("this.id2222222", this.id);
      GetOrderLogisticDetailbyVendor({ id: this.id })
        .then((res) => {
          console.log("GetOrderDetail-res:", res);
          const { orderList, store, ...orderData } = res.data.order;
          this.avatarUrl = res.data.trackimg ? res.data.trackimg : '';
          this.trackingData.trackingNumber = res.data.trackingNumber ? res.data.trackingNumber : '';
          this.trackingData.trackingInformation = res.data.tracking ? res.data.tracking : '';
          this.orderList = orderList.map((v) => {
            console.log(v, "orderlist");
            return {
              ...v,
              productImg: v.productImg || v.productGalleryImg,
              currencyTo: v.currencyTo,
            };
          });
          this.orderAttachList = store;
          this.$refs["orderAttachList"] &&
            this.$refs["orderAttachList"].setList(store);
          const tType = orderList.filter((v) => v.tType === 201);
          const logisticsTotal = tType.map((v) => v.logisticsTotal);
          const {
            // 订单
            orderNo,
            total,
            status,
            // createAt,
            // updateAt,
            storeId,
            id,
            logisticsType,
            storeName,
            userId,
            username,
            avatar,
            ...others
          } = orderData;

          this.orderData = {
            orderNo: `${orderNo}`,
            total: total * 0.98,
            status,
            // createAt: formatDataTime(createAt),
            // updateAt: formatDataTime(updateAt),
            // consignee: `${consignee} ${phone} ${email}`,
            consignee: `SOKOGATE  +86-18813759438`,
            // address: `${country} ${province} ${city} ${district} ${detail}`,
            address: this.$t("content.order.parAddres"),
            logisticsTotal: logisticsTotal[0],
            // phoneCode: '86',
            country: this.$t("categorys.China"),
            logisticsType:
              logisticsType == 301
                ? this.$t("offer.airTransport")
                : this.$t("offer.oceanShipping"),
          };
          // console.log(orderData, "orderData");
          this.store = { storeId, storeName, id };
          this.user = { userId, username, avatar };
          console.log("others:", others);
        })
        .catch((err) => {
          console.log("GetOrderDetail-err:", err);
        });
    },
    goBack() {
      this.$router.back(-1);
    },
  },
};
</script>
  
<style lang="scss">
// .el-icon-picture-outline {
//   width: 50px;
//   height: 50px;
//   margin-left: 10px;
// }

.col-flex {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;

  & > * {
    margin-right: 10px;
    margin-bottom: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}
.detail-h4 {
  color: #333;
  background: transparent;
  border: 1px solid transparent;
  padding: 12px 10px;
  font-size: 18px;
  font-weight: normal;
}
</style>